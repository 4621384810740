<template>
<div>
  <b-container fluid>
    <div class="d-flex justify-content-center" v-if="loading"><div class="spinner"></div></div>
    <b-row v-if="!loading" align-h='center'>
      <b-col cols="12" md='8'>
        <h2 class='team-title d-none d-md-block'></h2>
        <div class="teamlist-table">
          <b-table head-variant="dark" responsive v-if="teams.length > 0" striped hover :items="teams" :fields="fields"
                  @row-clicked='onTeamListRowClicked'>
            <template #cell(actions)="row">
              <b-btn size="sm" @click.stop="view(row.item.id)">View</b-btn>
            </template>
          </b-table>
        </div>
        <div class="add-team-button d-flex justify-content-center">
            <b-button href="/addteam">Add New Team</b-button>
        </div>
        <ul v-if="errors && errors.length">
          <li v-for="error of errors"
              :key="error.id">
            {{error.message}}
          </li>
        </ul>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>

import { store } from '../store'
import { auth } from '../firebaseConfig/'

export default {
  name: 'TeamList',
  data () {
    return {
      fields: [
        { key: 'name', label: 'Teams', sortable: false, 'class': 'text-left text-nowrap' },
        { key: 'leagueName', label: 'League Name', 'class': 'text-left text-nowrap d-none d-md-table-cell', sortable: false },
        { key: 'actions', label: '', 'class': 'text-center d-none d-md-table-cell' }
      ],
      teams: [],
      loading: true,
      errors: []
    }
  },
  computed: {
    userProfile () {
      return store.state.userProfile
    }
  },
  async created () {
    if (this.userProfile.teams.length > 0) {
      this.teams = await store.dispatch('getTeamsByIds', this.userProfile.teams)
    }
    this.setNav()
    this.loading = false
  },
  methods: {
    comingSoon () {
      alert("Sorry! Coming Soon!")
    },
    setNav () {
      store.commit('updateNav', {
        backLink: '',
        teamLink: '',
        teamName: ''
      })
    },
    logout () {
      auth.signOut().then(() => {
        store.dispatch('clearData')
        this.$router.push('/login')
      }).catch(err => {
        console.log(err)
      })
    },
    onTeamListRowClicked (team) {
      this.$router.push({
        name: 'TeamDetail',
        params: { teamId: team.id }
      })
    },
    view (id) {
      this.$router.push({
        name: 'TeamDetail',
        params: { teamId: id }
      })
    }
  }
}
</script>
